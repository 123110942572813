import submissionClosed from './assets/submissions_closed.jpg';

const Submit = () => {
  return (
    <div className="flex flex-col items-center">
      <h1 className="text-2xl mb-1 font-bold sm:text-1xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">CALL FOR SUBMISSIONS</h1>
      <h2 className="text-sm text-center mb-6 sm:text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl">DEADLINE: OCTOBER 15TH</h2>
      <div className="flex flex-col text-sm text-center mb-6 sm:text-xs md:text-sm lg:text-base xl:text-lg 2xl:text-xl w-3/5 gap-3">
        <p>Twelve Vacancies is seeking student or youth-made films that fit under the themes of experimental, uncanny, or horror and are under 10 minutes long.
          Films will be screened on November 22nd at 3475 Rue Peel.</p>
        <p>To submit, please send an email to <a href="mailto:twelvevacanciesfilmfest@gmail.com" className="underline">twelvevacanciesfilmfest@gmail.com</a> containing a 
          Google Drive or Wetransfer link to your film, or submit
          through <a href="https://filmfreeway.com/TwelveVacanciesFilmFestival" target="_blank" rel="noreferer" className="underline">FilmFreeway</a>.</p>
      </div>
      <img src={submissionClosed} className="w-1/2"/>
    </div>
  );
}

export default Submit;