import image404 from './assets/twelvevacancies_linedrawing.png';

const PageNotFound = () => {
  return (
    <div className="flex flex-col items-center w-full gap-2 xl:gap-4">
      <img src={image404} className="w-32 h-auto sm:w-48 xl:w-64 2xl:w-72" alt=""/> 
      <h1 className="text-red-500 sm:text-xs sm:max-w-full md:text-sm lg:text-base xl:text-lg 2xl:text-xl">404 page not found.</h1>
    </div>
  );
}

export default PageNotFound;