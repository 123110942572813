import swaddle from './assets/films/swaddle.jpeg';
import fiveSeven from './assets/films/5-7.png';
import anEgo from './assets/films/an-ego-and-i.png';
import dontTakeCandy from './assets/films/dont-take-candy.png';
import everyFrameAPainting from './assets/films/every-frame-a-painting.png';
import motherDearest from './assets/films/mother-dearest.png';
import flyman from './assets/films/flyman.png';
import settleUp from './assets/films/settle-up.png';
import theEnd from './assets/films/the-end.png';
import travellingInTracy from './assets/films/travelling-in-tracy.png';
import weftward from './assets/films/weftward.png';
import letsEndTheNightHere from './assets/films/lets-end-the-night-here.png';

const Home = () => {
  const films = [
    {
      title: "SWADDLE",
      director: "Pepi Eirew",
      description: (<p>The winner of our fan favourite award, <i>Swaddle</i> tells the story of a 
      young girl as she finds family on an 
      ancestry website and travels to
      meet them. Pepi Eirew's thesis film at 
      CalArts is a quirky take on the joys 
      and pitfalls of DNA disclosure.</p>),
      credits: (<p>
        Directed, written and animated by
        Pepi Eirew
        (@pepiandthecreatures),
        music by Gabe Le Neveu,
        voice acting by Claire Seckler and Pepi Eirew.
      </p>),
      award: "",//"WINNER: FAN FAVOURITE AWARD",
      image: swaddle
    }, 
    {
      title: "5-7, TWOMBLY (IMPROVISATION)",
      director: "Elizabeth Tétaz",
      description: (<p>
        <i>5-7 and Twombly (Improvisation)</i> are two animated
        short films that pair distinctive soundscapes with beautiful, engaging visuals.
      </p>),
      credits: (<p>
        Animation, music, and sound design by Elizabeth Tétaz,
        additional music by Charles Tétaz.
      </p>),
      image: fiveSeven
    },
    {
      title: "DON'T TAKE CANDY FROM STRANGERS",
      director: "Jo Gaffney",
      description: (<p>
        A young, newlywed couple begins to settle into sweet married bliss together, 
        but when one of them unburies a startling discovery, things are revealed not to be as sweet as they seem.
      </p>),
      credits: (<p>
        Directed by Jo Gaffney, written by Jo Gaffney, Raunie Mae, Soph Mazzocchio, and C.G Warkentin, edited by Raunie Mae,
        starring Jackie Barr and Zach Wismer, with cinematography by Rachel Chen.
      </p>),
      image: dontTakeCandy
    },
    {
      title: "TRAVELLING IN TRACY",
      director: "Kate Henderson and Solomon Le",
      description: (<p>
        Tracy leaves her neglectful home only to discover she can’t escape the inevitable corruption of feminine youth.
      </p>),
      credits: (<p>
        Directed and produced by Kate Henderson and Solomon Le,
        written by Kate Henderson,
        starring Ayla Evans, Kyla Ward, Hans Gorre, and Curtis Maciborski
      </p>),
      image: travellingInTracy
    },
    {
      title: "AN EGO AND I",
      director: "Jesu Medina",
      description: (<p>
        <i>An Ego and I</i> explores the fragility of the human psyche and the far-reaching consequences of our actions.
      </p>),
      credits: (<p>
        Written and directed by Jesu Medina, music composed by Darias Lanham, starring Jessika Khristina
      </p>),
      image: anEgo
    },
    {
      title: "MOTHER, DEAREST",
      director: "Mac Petit",
      description: (<p>
        When Barry, a repressed, 30-something year old man, returns home to find his dementiating Mother sick and covered in spew, he is forced to cancel his evening date and take care of her. As resentment builds and his alcoholic tendencies are used to cope, the two get in a drunk and confused fight.
      </p>),
      credits: (<p>
        Written and directed by Mac Petit, produced by Julz LaBrash, cinematography by Tristan Ramos, edited by Tristan Ramos and Bernard Tang, 
        starring Joanna Rannelli, Liam McCulley, and Raven Light
      </p>),
      image: motherDearest
    },
    {
      title: "EVERY FRAME A PAINTING",
      director: "Kira Doxtator",
      description: (<p>
        <i>Every Frame a Painting</i> is an homage to one of the first great filmmakers, Georges Méliès, and his devoted craft.
      </p>),
      credits: (<p>
      </p>),
      image: everyFrameAPainting
    },
    {
      title: "FLYMAN",
      director: "Ethan White",
      description: (<p>
        A perpetually floating man, struggling to exist in the dark and terrifying world that surrounds him, manages to find impossible purpose in washing his sheets.
      </p>),
      credits: (<p>
        Written and directed by Ethan White, music composed by Colby Stockdale,
        starring Skylar Somnus, Hana Huggins, Juan José Cadotte, and Willem Martzke
      </p>),
      image: flyman
    },
    {
      title: "THE END",
      director: "Niko Chu",
      description: (<p>
        Inspired by stories of near death experiences, <i>The End</i> is an immersive experimental 
        animation that plays a game with the audience: to stare at the middle for as long as possible 
        while metaphysical horrors branch around your peripheral.
      </p>),
      credits: (<p>
      </p>),
      image: theEnd
    },
    {
      title: "SETTLE UP",
      director: "Owen Maloney and Emma Clark",
      description: (<p>
        A distraught renter needs to settle up a tab of $334.18 with her reclusive roommate.
      </p>),
      credits: (<p>
        Written and directed by Owen Maloney and Emma Clark, 
        starring Saylor McPherson, Sara Holub, and Jack Putnam
      </p>),
      image: settleUp
    },
    {
      title: "LET'S END THE NIGHT HERE",
      director: "Jo Gaffney",
      description: (<p>
        A young man goes into the woods with two freaky chicks thinking his luck with women will finally change. 
        This is a story about fresh starts and finding what once was lost; including the key that was inside the whole time.
      </p>),
      credits: (<p>
        Written and directed by Jo Gaffney, cinematography and editing by Raunie Mae, starring Alex Love Kink, Shaye Steele, and Pluto Barr.
      </p>),
      image: letsEndTheNightHere
    },
    {
      title: "WEFTWARD, WORD/MEMORY/ECHO",
      director: "Elizabeth Tétaz",
      description: (<p>
        <i>Weftward</i> and <i>Word/Memory/Echo</i> are two animated
        short films that pair distinctive soundscapes with beautiful, engaging visuals.
      </p>),
      credits: (<p>
        Animation, music, and sound design by Elizabeth Tétaz,
        additional music by Charles Tétaz.
      </p>),
      image: weftward
    }
  ];

  return (
    <div className="flex flex-col gap-2 sm:gap-3">
      <div className="home-heading">
        <h1 className="text-2xl font-bold sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl lg:mb-3">WINTER 2024</h1>
      </div>
      <div className="films flex flex-col gap-8 lg:gap-12">
        {films.map(film => {
          return (
          <div className="film-card flex flex-row justify-between items-start sm:gap-5 md:gap-6 lg:gap-12 2xl:gap-24">
            <div className="flex flex-col gap-2 sm:w-1/2">
              <div className="flex flex-col">
                <h3 className="font-sans font-bold text-base sm:text-sm md:text-base lg:text-lg xl:text-2xl 2xl:text-3xl"><i>{film.title}</i> </h3>
                <h4 className="font-bold text-sm sm:text-xs sm:max-w-full md:text-sm lg:text-base xl:text-lg 2xl:text-xl">By {film.director}</h4>
              </div>
              { film.award ?
                <div className="bg-tvpurple text-sm p-2 my-1 w-max sm:text-xs sm:px-1 md:px-2 md:mb-4 md:text-sm lg:text-base xl:text-xl 2xl:text-2xl 2xl:p-3">
                  <h4>{film?.award}</h4>
                </div>
              : <></>
              }
              <div className="w-full max-w-80 sm:hidden">
                <img src={film.image} className=""/>
              </div>  
              <div className="film-desc flex flex-col max-w-80 text-sm gap-2 sm:text-xs sm:max-w-full md:text-sm lg:text-base xl:text-lg 2xl:text-xl">
                {
                  film.description
                }
                {
                  film.credits
                }
              </div>
            </div>
            <div className="hidden sm:block sm:w-1/2 sm:mt-1">
                <img src={film.image}/>
            </div>  
          </div>
          )
        })
      }
      </div>
    </div>
  );
}

export default Home;