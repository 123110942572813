import getInvolvedImage from './assets/get_involved.jpg';

const GetInvolved = () => {
  return (
    <div className="flex flex-col justify-between gap-6 sm:flex-row sm:gap-10 md:gap-14 lg:gap-20 xl:gap-24 2xl:gap-28">
      <div className="flex flex-col justify-start items-start sm:w-8/12 gap-3">
        <h1 className="font-bold text-2xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">LOOKING TO GET INVOLVED?</h1>
        <p className="text-sm sm:text-2xs md:text-xs lg:text-sm xl:text-base 2xl:text-xl">We’re so glad you’ve found us!</p>
        <p className="text-sm sm:text-2xs md:text-xs lg:text-sm xl:text-base 2xl:text-xl">
          We’ll be looking for volunteers for our Fall 2024 season to help with
          advertising and promotion, event coordination, and ticket sales. If
          you’re interested in joining the team, send an email
          to <a href="mailto:twelvevacanciesfilmfest@gmail.com" className="underline">twelvevacanciesfilmfest@gmail.com</a> with 
          your name, year + major, your favourite movie, and why you’re interested in
          volunteering! Also let us know if you have any relevant experience or
          special skills (ie Adobe Suite, film production experience, etc).
        </p>
        <p className="text-sm sm:text-2xs md:text-xs lg:text-sm xl:text-base 2xl:text-xl">
          We are ALSO looking for visual artists who are interested in designing
          promotional materials like posters and stickers. If this is you, send us
          an email or DM us on <a href="https://www.instagram.com/twelvevacancies/" target="_blank" rel="noreferer" className="underline">Instagram!</a>
        </p>
      </div>
      <div className="w-3/4 sm:w-4/12">
        <img src={getInvolvedImage} alt="Two little guys holding hands looking at a painting of houses"/>
      </div>
    </div>
  );
}

export default GetInvolved;